<template>
  <div
    class="grey--text subtitle-2 text-center center "
    style="max-width: 400px"
  >
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageTips",
}
</script>
