<template>
  <div class="d-flex">
    <v-spacer />
    <div v-if="displayedInfoMessage === message.id">
      <v-list-item-action-text
        v-if="message.status === 'read'"
        v-text="$t('quiz.evalmeeChat.readAt') + $moment(message.updated_at).format('LT')"
      />
      <v-list-item-action-text
        class="pl-2"
        v-if="!message.isGlobal"
        v-text="$t('quiz.evalmeeChat.sendedAt') + $moment(message.created_at).format('LT')"
      />
    </div>
    <div v-else>
      <v-icon
        v-if="message.isGlobal"
        v-text="'mdi-account-group-outline'"
        small
      />
      <v-list-item-action-text
        v-text="$moment(message.updated_at).format('LT')"
        class="ml-1"
      />
      <v-icon
        v-if="message.status === 'sent' && message.user_id === currentUser.id"
        v-text="'mdi-check'"
        class="ml-1"
        small
      />
      <v-icon
        v-if="message.status === 'read' && message.user_id === currentUser.id"
        v-text="'mdi-check-all'"
        class="ml-1"
        small
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "MessageSendInfo",
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    displayedInfoMessage: {
      type: [Number, null],
      default: null,
    },
  },
  data: () => {
    return {
      displayInfoMessage: null,
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
  },
}
</script>
